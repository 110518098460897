<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>顧客情報編集</template>
              <template v-slot:body>
                <CustomerForm ref="form" :isEdit="true"></CustomerForm>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>セキュリティ</template>
              <template v-slot:body>
                <div class="scrollX">
                  <table class="listTable">
                    <tbody>
                      <tr v-if="!customerForm.isCpm">
                        <th>パスコード設定</th>
                        <template v-if="customerForm.hasPasscode">
                          <td class="listTable-fix" :class="{ 'listTable-toggle': usePaymentPasscode }">
                            <label class="form-toggle" v-if="usePaymentPasscode">
                              <input
                                class="form-toggle-input"
                                type="checkbox"
                                name="receptionStoreEmailFlag"
                                v-model="customerForm.usePaymentPasscode"
                                @change="handleUsePaymentPasscode"
                              />
                              <span class="form-toggle-set">
                                <span class="form-toggle-bg"></span>
                                <span class="form-toggle-circle"></span>
                              </span>
                            </label>
                            あり
                          </td>
                          <td class="listTable-fix">
                            <ActionButton class="btn btn-black" :handle-submit="handleDeletePasscode" button-text="リセットする" />
                          </td>
                        </template>
                        <template v-else>
                          <td class="listTable-fix" v-if="usePaymentPasscode">
                            <label class="form-toggle">
                              <input
                                class="form-toggle-input"
                                type="checkbox"
                                name="receptionStoreEmailFlag"
                                v-model="customerForm.usePaymentPasscode"
                                @change="handleUsePaymentPasscode"
                              />
                              <span class="form-toggle-set">
                                <span class="form-toggle-bg"></span>
                                <span class="form-toggle-circle"></span>
                              </span>
                            </label>
                          </td>
                          <td class="listTable-fix">なし</td>
                        </template>
                      </tr>
                      <tr v-for="event in newCustomerFormEvents" :key="event.index">
                        <th colspan="2">
                          {{ event.name }}
                          <br />
                          <div class="ml-15">
                            <span class="fw-n">
                              <br />
                              残高：¥{{ event.charged | comma }}
                              <br />
                              <template v-if="event.chargedBreakdown">
                                （使用できる金額）
                                <br />
                                <p
                                  v-for="(item, index) in event.chargedBreakdown"
                                  :key="index"
                                >
                                  {{ ` (${item.name}) ￥${formatLocalString(item.amount)} まで` }}
                                </p>
                              </template>
                              <br />
                              <div class="ml-20" v-if="event.isCreditCard">クレジットカード登録： {{ event.hasCreditCard ? 'あり' : 'なし' }}</div>
                              <div class="ml-20" v-if="event.isMaruPay">
                                <template v-if="event.isCreditCard">
                                  <template v-if="event.isDeleteBtnShown">
                                    <br />
                                  </template>
                                  <br />
                                </template>
                                銀行口座登録： {{ event.hasBankAccount ? 'あり' : 'なし' }}
                              </div>
                            </span>
                          </div>
                        </th>
                        <td v-if="event.isDeleteBtnShown" class="listTable-fix vertical-bottom">
                          <template v-if="event.isCreditCardShown">
                            <ActionButton class="btn btn-black" :handle-submit="() => openDeleteBankModal('creditCard', event)" button-text="リセットする" />
                          </template>
                          <template v-if="event.hasBankAccount && event.isMaruPay">
                            <template v-if="event.isCreditCardShown">
                              <br />
                              <br />
                            </template>
                            <ActionButton class="btn btn-black" :handle-submit="() => openDeleteBankModal('maruPay', event)" button-text="リセットする" />
                          </template>
                          <div class="h-50" v-else-if="event.isMaruPay"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <TableActivites :activities="activities"/>
          </div>
        </div>
      </section>
      <template v-if="isCommonPoint">
        <section class="section">
          <div class="row">
            <div class="col">
              <Panel type="sheet">
                <template v-slot:headline>
                  ポイント
                </template>
                <template v-slot:body>
                  <div class="scrollX">
                    <table class="listTable">
                      <tbody>
                        <tr :class="{ 'tr-nested': expiration }">
                          <th>総保有ポイント残高</th>
                          <th class="listTable-fix text-right">{{ totalPoint | comma }}P</th>
                        </tr>
                        <tr v-if="expiration">
                          <td>{{ expiration | customShortDate }}まで</td>
                          <td class="listTable-fix text-right">{{ expirePoint | comma }}P</td>
                        </tr>
                        <tr v-for="pointDetail in pointHistory" :key="pointDetail.index">
                          <th>
                            <span class="pre-line">{{ endline(pointDetail.newTypeName) }}</span>
                            <span :class="{ 'amount-point': !pointDetail.plus }">
                              {{ pointDetail.newAmountPoint }}
                            </span>
                          </th>
                          <td class="listTable-fix">{{ pointDetail.createDate | fullDate }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ul class="listGrid" v-if="pointActivities.length > activitiesLength">
                    <li class="listGrid-item pos-end">
                      <button class="btn btn-black" type="button" @click="showModal('pointActivityModal')">詳細を見る</button>
                    </li>
                  </ul>
                  <Modal @close="closeModal('pointActivityModal')" v-if="pointActivityModal">
                    <template v-slot:headline>ポイント履歴</template>
                    <template v-slot:body>
                      <div class="scrollX">
                        <table class="listTable">
                          <tbody>
                            <tr v-for="activity in pointActivities" :key="activity.index">
                              <th>
                                <span class="pre-line">{{ endline(activity.newTypeName) }}</span>
                                <span :class="{ 'amount-point': !activity.plus }">
                                  {{ activity.newAmountPoint }}
                                </span>
                              </th>
                              <td class="listTable-fix">{{ activity.createDate }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>
                    <template v-slot:footer>
                      <ul class="listGrid justify-content-end">
                        <li class="listGrid-item">
                          <button class="btn btn-white" type="button" @click="closeModal('pointActivityModal')">閉じる</button>
                        </li>
                      </ul>
                    </template>
                  </Modal>
                </template>
              </Panel>
            </div>
          </div>
        </section>
      </template>
      <template v-else-if="isPrivatePoint">
        <section class="section" v-for="childEventPoint in childEventPointList" :key="childEventPoint.index">
          <div class="row">
            <div class="col">
              <Panel type="sheet" :isPointEvent="true">
                <template v-slot:headline>
                  <div class="d-flex justify-content-between">
                    <span>ポイント</span>
                    <span>{{ childEventPoint.eventName }}</span>
                  </div>
                </template>
                <template v-slot:body>
                  <div class="scrollX">
                    <table class="listTable">
                      <tbody>
                        <tr :class="{ 'tr-nested': childEventPoint.expiration }">
                          <th>総保有ポイント残高</th>
                          <th class="listTable-fix text-right">{{ childEventPoint.totalPoint | comma }}P</th>
                        </tr>
                        <tr v-if="childEventPoint.expiration">
                          <td>{{ childEventPoint.expiration | customShortDate }}まで</td>
                          <td class="listTable-fix text-right">{{ childEventPoint.expirePoint | comma }}P</td>
                        </tr>
                        <tr v-for="pointDetail in pointHistory(childEventPoint)" :key="pointDetail.index">
                          <th>
                            <span class="pre-line">{{ endline(pointDetail.newTypeName) }}</span>
                            <span :class="{ 'amount-point': !pointDetail.plus }">
                              {{ pointDetail.newAmountPoint }}
                            </span>
                          </th>
                          <td class="listTable-fix">{{ pointDetail.createDate | fullDate }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ul class="listGrid" v-if="childEventPoint.activitiesLength > activitiesLength">
                    <li class="listGrid-item pos-end">
                      <button class="btn btn-black" type="button" @click="onShowPointModal(childEventPoint.eventName)">詳細を見る</button>
                    </li>
                  </ul>
                </template>
              </Panel>
            </div>
          </div>
        </section>
      </template>
    </div>
    <!--[modal]-->
    <Modal @close="closeModal('childPointActivityModal')" v-if="childPointActivityModal">
      <template v-slot:headline>ポイント履歴</template>
      <template v-slot:body>
        <div class="scrollX">
          <table class="listTable">
            <tbody>
              <tr v-for="activity in childPointActivities" :key="activity.index">
                <th>
                  <span class="pre-line">{{ endline(activity.newTypeName) }}</span>
                  <span :class="{ 'amount-point': !activity.plus }">
                    {{ activity.newAmountPoint }}
                  </span>
                </th>
                <td class="listTable-fix">{{ activity.createDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button class="btn btn-white" type="button" @click="closeModal('childPointActivityModal')">閉じる</button>
          </li>
        </ul>
      </template>
    </Modal>
    <!--[/modal]-->
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ul class="listGrid">
              <li class="listGrid-item">
                <button class="btn btn-red" type="button" @click="showModal('deleteModal')">削除</button>
              </li>
              <li class="listGrid-item" v-if="!isClientChild">
                <button class="btn btn-yellow" type="button" @click="showModal('pauseModal')">{{ !paused ? '一時停止' : '再開' }}</button>
              </li>
            </ul>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <button class="btn btn-white" type="button" @click="redirectToPreviousPage">キャンセル</button>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-main" :handle-submit="edit" button-text="編集する" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('deleteModal')" v-if="deleteModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">対象の顧客情報を削除しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('deleteModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-red" :handle-submit="handleDeleteCustomer" button-text="削除する" />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('pauseModal')" v-if="pauseModal">
      <template v-slot:headline>{{ paused ? '再開' : '一時停止' }}</template>
      <template v-if="paused" v-slot:body>
        <p class="description text-align-center">一時停止を解除し、この顧客のログインを再開しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-yellow" :handle-submit="changeStatus" button-text="再開する" />
          </li>
        </ul>
      </template>
      <template v-else v-slot:body>
        <p class="description text-align-center">一時停止し、この顧客のログインを止めますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-yellow" :handle-submit="changeStatus" button-text="一時停止にする" />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('cardModal')" v-if="cardModal">
      <template v-slot:headline>{{ cardTitle }}</template>
      <template v-slot:body>
        <p class="description text-align-center">{{ cardDescription }}</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('cardModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-black" :handle-submit="handleDeleteCard" :button-text="cardText" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixin
import nav from '@/mixins/nav/user';
import password from '@/mixins/plugin/password';
import modal from '@/mixins/plugin/modal';
import error from '@/mixins/plugin/error';
import edit from '@/mixins/plugin/edit';
import point from '@/mixins/plugin/point';
//helper
import { formatDateAndTime, formatLocalString, endline } from '@/helpers/formatData';
//component
import CustomerForm from './Form';
import Modal from '@/components/Modal.vue';
import TableActivites from '@/components/TableActivites.vue';
//constants
import { EventConstants } from '@/constants/event';

export default {
  name: 'EventUserEdit',
  data: function() {
    return {
      pageName: '顧客管理',
      date: '',
      pointActivityModal: false,
      childPointActivityModal: false,
      deleteModal: false,
      pauseModal: false,
      cardModal: false,
      storeModule: 'customer',
      formatLocalString,
      endline,
      childPointActivities: [],
      activitiesLength: EventConstants.ACTIVITIES_MAX_LENGTH,
      shopArray: [0, 5, 8],
      emptyShopArray: [1, 4],
      categoryArray: [2, 6],
      shopNameArray: [3, 7, 9, 10, 11],
      qrPointType: 6,
      modalType: '',
      cardDescription: '',
      cardText: '',
      cardTitle: '',
    };
  },
  computed: {
    ...mapGetters({
      customerForm: 'customer/customerDetail',
      customerActivities: 'customer/customerActivities',
      customerPointActivities: 'parentPoint/customerPointActivities',
      isClientChild: 'auth/isClientChild',
      parentEventDetail: 'event/parentEventDetail',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    customerId() {
      return this.$route.params.customerId;
    },
    isCommonPoint() {
      return this.customerForm?.isCommonPoint;
    },
    isPrivatePoint() {
      return this.customerForm?.isPrivatePoint;
    },
    totalPoint() {
      return this.customerForm?.commonPoint?.totalPoint;
    },
    expiration() {
      return this.customerForm?.commonPoint?.expiration;
    },
    expirePoint() {
      return this.customerForm?.commonPoint?.expirePoint;
    },
    pointHistory() {
      const mapFunction = (e) => this.customDataActivities(e);
      return this.isPrivatePoint ? item => item?.pointHistory.map(mapFunction) : this.customerForm?.commonPoint?.pointHistory.map(mapFunction);
    },
    childEventPointList() {
      return this.customerForm?.privatePoint.map(e => {
        const dataLength = this.customerPointActivities?.[e.eventName]?.list?.length;
        return {
          ...e,
          totalPoint: e?.totalPoint || 0,
          expiration: e?.expiration || '',
          expirePoint: e?.expirePoint || 0,
          activitiesLength: dataLength || 0
        }
      });
    },
    activities(){
      return this.customerActivities.map(item => {
        return {
          ...item,
          createDate: formatDateAndTime(item.createDate),
          action: item.action,
        }
      })
    },
    pointActivities() {
      return (this.customerPointActivities?.list || []).map(item => this.customDataActivities(item));
    },
    paused() {
      return !this.customerForm.validFlag;
    },
    newCustomerFormEvents() {
      return (this.customerForm?.events || []).map(e => {
        const isUseCreditCard = e.useCreditCard && !this.customerForm?.isCpm;
        return {
          ...e,
          isCreditCard: isUseCreditCard,
          isMaruPay: e.useMaruPay && !this.customerForm?.isCpm,
          isCreditCardShown: e.hasCreditCard && isUseCreditCard,
          isDeleteBtnShown: !this.customerForm?.isCpm && ((e.useCreditCard && e.hasCreditCard) || (e.useMaruPay && e.hasBankAccount)),
        }
      });
    },
    isMaruPayModal() {
      return this.modalType === 'maruPay';
    },
    historyPage() {
      return sessionStorage.getItem('historyPage');
    },
    historyDirectory() {
      return sessionStorage.getItem('historyDirectory');
    },
    usePaymentPasscode() {
      return !!this.parentEventDetail?.usePaymentPasscode;
    }
  },
  mixins: [nav, error, password, modal, edit, point],
  components: {
    Modal,
    CustomerForm,
    TableActivites,
  },
  methods: {
    edit() {
      this.$refs.form.handleSubmit()
    },
    redirectToPreviousPage() {
      if (!this.historyPage) {
        this.$router.push({ name: 'EventUser' });
      } else {
        this.$router.push({ name: this.historyPage, params: { directory: this.historyDirectory } });
      }
    },
    async changeStatus() {
      const result = await this.dispatchAction('customer/updateCustomerStatus', {
        status: this.paused,
      });
      this.closeModal('pauseModal');
      if (result) {
        this.$message.updated('status');
      }
    },
    async handleDeletePasscode() {
      const result = await this.dispatchAction('customer/deletePasscode');
      if (result) {
        this.$message.deleted('passcode');
        this.$refs.form.getActivities();
      }
    },
    async openDeleteBankModal(type, event) {
      this.modalType = type;
      this.cardDescription = event?.name + (this.isMaruPayModal ? 'の子イベントにて、この顧客の口座情報を削除しますか？' : 'の子イベントにて、この顧客のクレジットカード情報を削除しますか？');
      this.cardText = this.isMaruPayModal ? '口座情報を削除する' : 'クレジットカード情報を削除する';
      this.cardTitle = this.isMaruPayModal ? '口座情報削除' : 'クレジットカード情報削除';
      this.cardDirectory = event?.directory;
      this.showModal('cardModal');
    },
    async handleDeleteCard() {
      const action = this.isMaruPayModal ? 'deleteMaruPay' : 'deleteCreditCard';
      const message = this.isMaruPayModal ? 'maruPaySuccess' : 'creditCardSuccess';
      const result = await this.dispatchAction(`customer/${action}`, { directory: this.cardDirectory });
      if (result) {
        this.dispatchAction('customer/getCustomerDetail');
        this.$message.showSuccess(message);
        this.$refs.form.getActivities();
        this.closeModal('cardModal');
      }
    },
    async handleDeleteCustomer() {
      const result = await this.dispatchAction('customer/deleteCustomer');
      if (result) {
        await this.$router.push({ name: 'EventUser' });
        this.$message.deleted('customer');
      }
    },
    dispatchAction(actionPath, payload) {
      return this.$store.dispatch(actionPath, {
        subdomain: this.subdomain,
        customerId: this.customerId,
        ...payload,
      })
    },
    customDataActivities(item) {
      const shopName = item?.shop ? (item?.shop + '｜') : '';
      const plus = !item?.plus ? '-' : '';
      const typeName = this.getPointType(item.type);
      return {
        ...item,
        createDate: formatDateAndTime(item.createDate),
        newTypeName: `${typeName}｜${shopName}`,
        newAmountPoint: item.plus && !item.status ?
          `(${formatLocalString(item?.amountPoint)}P)` : `${plus + formatLocalString(item?.amountPoint)}P`
      }
    },
    onShowPointModal(eventName) {
      this.showModal('childPointActivityModal');
      this.childPointActivities = this.customerPointActivities[eventName]?.list.map(item => this.customDataActivities(item)) || [];
    },
    async handleUsePaymentPasscode() {
      const result = await this.$store.dispatch('customer/updateUsePaymentPasscode', {
        usePaymentPasscode: this.customerForm?.usePaymentPasscode,
        subdomain: this.subdomain,
        customerId: this.customerId,
      });
      if (result) {
        this.$message.showSuccess('changeToggleSuccess');
        await this.$store.dispatch('event/getParentEventDetail', this.subdomain);
      }
    }
  },
  beforeDestroy() {
    if (this.$route.name) {
      sessionStorage.removeItem('historyPage');
      sessionStorage.removeItem('historyDirectory');
    }
  }
};
</script>


<style scoped>
.listTable {
  display: block;
  overflow-x: auto;
}

.tr-nested {
  border-bottom: 0 !important;
}

.amount-point {
  color: #fd667e;
}


.h-50 {
  height: 50px;
}
</style>
